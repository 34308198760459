#printer  {
    display: none;
}
@media print {
	#printer  {
        display: block;
        font-family: 'Nunito', sans-serif;
        z-index: 99999999;
	}
}
.print-divider{
    width: 100%;
    border:1px dotted black
}